import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { StaticSiteGeneratorListDataInterface } from "../@types/shortcodes/shortcodes"

/**
 * Generate table of static site generators
 *
 * @constructor
 */
const StaticSiteGeneratorList: React.FC = () => {
  const data: StaticSiteGeneratorListDataInterface = useStaticQuery(graphql`
    query StaticGeneratorListQuery {
      allStaticSiteGenerator {
        edges {
          node {
            name
            website
            github
            githubUrl
            license
            language
            created(formatString: "DD-MM-YYYY")
            stars
          }
        }
      }
    }
  `)

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Github</th>
          <th>License</th>
          <th>Language</th>
          <th>Created</th>
          <th>Stars</th>
        </tr>
      </thead>
      <tbody>
        {data.allStaticSiteGenerator.edges.map(({ node }, i) => (
          <tr key={i}>
            {node.website ? (
              <td>
                <a href={node.website}>{node.name}</a>
              </td>
            ) : (
              <td>{node.name}</td>
            )}
            {node.githubUrl ? (
              <td>
                <a href={node.githubUrl}>{node.github}</a>
              </td>
            ) : (
              <td>{node.github}</td>
            )}
            <td>{node.license}</td>
            <td>{node.language}</td>
            <td>{node.created}</td>
            <td>{node.stars}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default StaticSiteGeneratorList
